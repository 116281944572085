import React, { useContext, useMemo } from "react";
import { FinanceContext } from "../../../context/finance-context";
import { useBuyingPowerHistoryByScenario } from "../../../hooks/scenario.hooks";
import { buildChartDataFromObject } from "../../../utils/chart-util";
import ScenarioLineChartComponent from "../../finance/borrowing-capacity/components/scenario-line-chart-component";

export default function MaxBuyingPowerChart({ scenarioObject }) {
  const { selectedScenarioId } = useContext(FinanceContext);
  const {
    data: buyingPowerHistory,
    isFetching: gettingBuyingPowerHistory,
  } = useBuyingPowerHistoryByScenario(selectedScenarioId);

  const maxBuyingPowerChartData = useMemo(() => {
    return buildChartDataFromObject(buyingPowerHistory?.maxBuyingPowerList);
  }, [buyingPowerHistory?.maxBuyingPowerList]);

  return (
    <ScenarioLineChartComponent
      data={maxBuyingPowerChartData}
      estimateProperty={scenarioObject?.lvrSetting?.estimateProperty || 0}
      loadingState={gettingBuyingPowerHistory}
    />
  );
}
