import { Grid } from "@material-ui/core";
import React, { useContext, useMemo } from "react";
import Slider from "react-slick";
import {
  LINE_CHART_DASH,
  NON_LIABILITY_ACCOUNT_TYPES,
} from "../../../commons/enum";
import { mobileChartSliderSettings } from "../../../commons/slider-mobile-setting";
import {
  cashBalanceHistoryChartBaseOptions,
  toCashBalanceChartData,
  toTopLivingExpenseCategoriesChartData,
} from "../../../commons/transactions.common";
import DoughnutChartComponent from "../../../components/doughnut-chart-component";
import {
  LineChartComponent,
  LineChartMobileWrapper,
} from "../../../components";
import { FinanceContext } from "../../../context/finance-context";
import {
  useDetailAccountList,
  useLastXDaysFinanceHistory,
} from "../../../hooks/account.hooks";
import { useIsTablet } from "../../../hooks/common.hooks";
import {
  usePropertyList,
  useTotalPropertyDetail,
} from "../../../hooks/property.hooks";
import {
  useBuyingPowerHistoryByScenario,
  useScenarioList,
  useScenarioProductListByScenarioId,
} from "../../../hooks/scenario.hooks";
import { primaryColor } from "../../../scss/colors.scss";
import { buildFilteredPropertyChart } from "../../../utils/calculate-property-data-util";
import {
  buildChartDataFromObject,
  generateBuyingGoalData,
  generateReduceData,
} from "../../../utils/chart-util";
import { convertToInt } from "../../../utils/convert-number";
import { formatNumberWithDollar } from "../../../utils/numberFormater";
import IncomeExpenseHorizontalBarChart from "../../transactions/components/income-expense-horizontal-bar-chart";
import ChartContainer from "./chart-container";
import DashboardChartContainer from "./dashboard-chart-container";
import MaxBuyingPowerChart from "./max-buying-power-chart";
import MaxLoanHistoryChart from "./max-loan-history-chart";
import PropertyChartContainer from "./property-chart-container";
import SummaryContainer from "./summary-container";

const DashboardChartGroup = ({
  propertyList = [],
  scenarioList,
  scenarioObject,
}) => {
  const tabs = useMemo(() => {
    const _tabs = [];
    if (propertyList.length > 0) {
      _tabs.push({
        label: "Property Portfolio",
        chart: <PropertyChartContainer />,
      });
    }
    if (scenarioObject) {
      _tabs.push(
        {
          label: "Real Buying Power",
          chart: <MaxBuyingPowerChart />,
        },
        {
          label: "Max Loan",
          chart: <MaxLoanHistoryChart />,
        }
      );
    }
    return _tabs;
  }, [propertyList.length, scenarioObject]);
  if (propertyList.length === 0 && scenarioList.length === 0) {
    return <PropertyChartContainer />;
  }
  return <DashboardChartContainer chartList={tabs} />;
};
const CashBalanceChart = ({
  cashBalance = 0,
  cashBalanceHistoryChartData,
  cashBalanceHistoryChartBaseOptions: cashBalanceHistoryChartBaseOptionsProp,
}) => {
  const tabs = useMemo(() => {
    const _tabs = [];
    _tabs.push({
      label: `Cash Balance ${formatNumberWithDollar(cashBalance)}`,
      chart: (
        <LineChartComponent
          title="Cash Balance"
          lineChartData={cashBalanceHistoryChartData}
          customChartOptions={cashBalanceHistoryChartBaseOptionsProp}
        />
      ),
    });

    return _tabs;
  }, [
    cashBalance,
    cashBalanceHistoryChartData,
    cashBalanceHistoryChartBaseOptionsProp,
  ]);
  return <DashboardChartContainer chartList={tabs} />;
};

const ContentBodyComponent = ({
  isLoading,
  incomeData = {},
  deptData = {},
  summaryData = {},
  propertyPortfolio = {},
  top5LivingExpenses = [],
}) => {
  const { data: scenarioList = [] } = useScenarioList();
  const { data: propertyList = [] } = usePropertyList();
  const { selectedScenarioId, selectedScenarioDetail } = useContext(
    FinanceContext
  );
  const totalPropertyDetail = useTotalPropertyDetail();
  const { propertyValue = 0, currentDebt = 0 } = propertyPortfolio;
  const isTablet = useIsTablet();
  const { scenarioObject } = selectedScenarioDetail || {};
  const totalDebt = (currentDebt / propertyValue) * 100;
  const equity = propertyValue - currentDebt;
  const debtChartData = {
    data: [equity, currentDebt],
    title: ["Equity", "Debt"],
  };
  const { data: productList } = useScenarioProductListByScenarioId(
    selectedScenarioId
  );
  const { data: buyingPowerHistory } = useBuyingPowerHistoryByScenario(
    selectedScenarioId
  );

  const maxLoanHistoryChartData = useMemo(() => {
    return buildChartDataFromObject(buyingPowerHistory?.borrowCapacityList);
  }, [buyingPowerHistory?.borrowCapacityList]);

  const maxBuyingPowerChartData = useMemo(() => {
    return buildChartDataFromObject(buyingPowerHistory?.maxBuyingPowerList);
  }, [buyingPowerHistory?.maxBuyingPowerList]);

  const maxBuyingPower =
    !!productList && productList.length > 0
      ? Math.max(
          ...productList.map((productItem) => productItem.maxBuyingPower)
        )
      : 0;
  const maxTotalAmount =
    !!productList && productList.length > 0
      ? Math.max(...productList.map((productItem) => productItem.maxLoanAmount))
      : 0;

  const expensesDoughnutChartData = toTopLivingExpenseCategoriesChartData(
    top5LivingExpenses
  );

  const filteredChartData = useMemo(
    () => buildFilteredPropertyChart(totalPropertyDetail, "five-year"),
    [totalPropertyDetail]
  );

  const propertyChartData = {
    title: "Property EST",
    chartData: [
      {
        label: "Property Estimate",
        data: filteredChartData,
      },
    ],
    titleValue: propertyValue,
  };

  const chartSliderdata = [
    {
      title: "Real Buying Power",
      chartData: [
        {
          label: "Stretching it",
          data: maxBuyingPowerChartData,
          borderWidth: 1,
          lineSpacing: LINE_CHART_DASH,
        },
        {
          label: "Comfortably",
          data: generateReduceData(maxBuyingPowerChartData),
        },
        {
          label: "Buying Goal",
          data: generateBuyingGoalData(
            maxBuyingPowerChartData,
            scenarioObject?.lvrSetting.estimateProperty
          ),
          color: primaryColor,
          borderWidth: 1,
        },
      ],
      titleValue: maxBuyingPower,
    },
    {
      title: "Max Loan",
      chartData: [
        {
          label: "Stretching it",
          data: maxLoanHistoryChartData,
          borderWidth: 1,
          lineSpacing: LINE_CHART_DASH,
        },
        {
          label: "Comfortably",
          data: generateReduceData(maxLoanHistoryChartData),
        },
        {
          label: "Buying Goal",
          data: generateBuyingGoalData(
            maxLoanHistoryChartData,
            scenarioObject?.lvrSetting.estimateProperty
          ),
          color: primaryColor,
          borderWidth: 1,
        },
      ],
      titleValue: maxTotalAmount,
    },
  ];

  const numberOfDays = 30;

  const { data: financeHistory } = useLastXDaysFinanceHistory({
    numberOfDays,
  });

  const { data: detailAccountList } = useDetailAccountList();

  const calculateCashBalance = (accounts) => {
    const filteredAccounts = accounts.filter(
      (account) =>
        account.institutionDetail?.institutionType !== "Superannuation" &&
        NON_LIABILITY_ACCOUNT_TYPES.includes(account.class.type)
    );
    return filteredAccounts.reduce(
      (totalBalance, { balance = 0 }) => totalBalance + Number(balance),
      0
    );
  };
  const cashBalance = calculateCashBalance(detailAccountList);

  const cashBalanceHistoryChartData = toCashBalanceChartData(
    financeHistory?.cashBalances
  );

  const cashBalanceChartData = {
    title: "Total Cash Balance",
    chartData: [
      {
        label: "Cash Balance",
        data: cashBalanceHistoryChartData,
      },
    ],
    titleValue: cashBalance,
  };

  const totalIncome = financeHistory?.totalIncome || 0;
  const totalExpense = financeHistory?.totalExpense || 0;
  const cashFlowValue = totalIncome - Math.abs(totalExpense);

  return (
    <div className="dashboard-content">
      {isTablet && (
        <div className="mobile-chart-container mt-5">
          <LineChartMobileWrapper
            chartTitle={cashBalanceChartData.title}
            subTitle="(Last 30 Days)"
            titleValue={cashBalanceChartData.titleValue}
            chartData={cashBalanceChartData.chartData}
          />
        </div>
      )}
      <Grid container>
        {!isTablet && (
          <Grid item xs={12} lg={7}>
            <ChartContainer
              isLoading={isLoading}
              incomechartData={incomeData}
              deptchartData={deptData}
            />
          </Grid>
        )}

        <Grid item xs={12} lg={5} container alignItems="center">
          <div className="dashboard-cashflow-chart__container">
            <div className="dashboard-cashflow-chart__title">
              {`Cashflow ${cashFlowValue >= 0 ? "Positive" : "Negative"} `}
              <span className="dashboard-cashflow-chart__value">
                {formatNumberWithDollar(Math.abs(cashFlowValue))}
              </span>
              <span className="dashboard-cashflow-chart__time-range">
                (Last 30 Days)
              </span>
            </div>
            <IncomeExpenseHorizontalBarChart
              totalIncome={totalIncome}
              totalExpense={totalExpense}
            />
          </div>
        </Grid>
      </Grid>
      <SummaryContainer isLoading={isLoading} summaryData={summaryData} />
      {!isTablet ? (
        <>
          <CashBalanceChart
            cashBalance={cashBalance}
            cashBalanceHistoryChartData={cashBalanceHistoryChartData}
            cashBalanceHistoryChartBaseOptions={
              cashBalanceHistoryChartBaseOptions
            }
          />

          <DashboardChartGroup
            propertyList={propertyList}
            scenarioList={scenarioList}
            scenarioObject={scenarioObject}
          />
        </>
      ) : (
        <>
          {propertyList.length > 0 && (
            <div className="mobile-chart-container">
              <LineChartMobileWrapper
                chartTitle={propertyChartData.title}
                subTitle="(Last 5 yrs)"
                titleValue={propertyChartData.titleValue}
                chartData={propertyChartData.chartData}
              />
            </div>
          )}
          {!!totalDebt && (
            <div className="mobile-chart-container">
              <div className="da-property-doughnut-chart-container">
                <DoughnutChartComponent
                  data={debtChartData}
                  textTop={`${convertToInt(totalDebt)}%`}
                  textBottom="LVR"
                />
              </div>
            </div>
          )}
          {scenarioList.length > 0 && (
            <div className="mobile-chart-container">
              <Slider
                {...mobileChartSliderSettings}
                className="property-detail-slider"
              >
                {chartSliderdata.map((chart) => {
                  return (
                    <>
                      <LineChartMobileWrapper
                        chartTitle={chart.title}
                        subTitle="(Last 1 yr)"
                        titleValue={chart.titleValue}
                        chartData={chart.chartData}
                      />
                    </>
                  );
                })}
              </Slider>
            </div>
          )}
          {top5LivingExpenses.length ? (
            <div className="mobile-chart-container">
              <DoughnutChartComponent
                title="Expenses"
                data={expensesDoughnutChartData}
                textTop=""
                textBottom=""
                isBottomColor
                customLegend
              />
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default ContentBodyComponent;
